#home{
    #home-carousel_section{height: 100vh;
        .bx-viewport{height: 100vh !important;
            .carousel{ height: 100%;
                .carousel-item{background-position: center center ; background-size: cover ; height: 100%;}
            }            
        }
    }      
}

.carousel{ 
    .carousel-control{
        opacity: 1;
        &.left{
            span.glyphicon{
             left:20%;   
            }
        }

        &.right{
            span.glyphicon{
             right: 20%;   
            }
        }
    } 
}

@-webkit-keyframes jump {
    0%{
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    20%{
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    40%{
      -webkit-transform: translateY(-30px);
      transform: translateY(-30px);
    }
    50%{
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    60%{
      -webkit-transform: translateY(-15px);
      transform: translateY(-15px);
    }
    80%{
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100%{
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
}
  
@keyframes jump {
    0%{
        transform: translateY(0);
    }
    20%{
        transform: translateY(0);
    }
    40%{
        transform: translateY(-30px);
    }
    50%{
        transform: translateY(0);
    }
    60%{
        transform: translateY(-15px);
    }
    80%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(0);
    }
}


#home-carousel_section{border-bottom:none;overflow: hidden;position: relative;
    .pageDown{position: absolute;bottom: 5%;width: 100%;text-align: center;z-index: 999;font-size:3em;
        a{color:$white;display: inline-block;padding:0;-webkit-animation: jump 1.5s ease-out 0s infinite normal; animation: jump 1.5s ease-out 0s infinite normal;}
    }
    .bx-wrapper{margin:0px;border:none;box-shadow: none;background: none;
        .bx-controls-direction{            
            a{position: absolute;top:0;height: 100%;background: none;display: flex;justify-content: center;flex-direction: column;text-indent: unset;text-decoration: none;font-family: FontAwesome;margin: 0;
                &:after{color:$white;display: block;width:35px;height:35px;border-radius:35px;text-align: center;padding:5px;transition:all 0.2s;font-size: 18px;background-color:rgba(0, 0, 0, 0.5)}
                &:hover::after{ background-color:rgba(0, 0, 0, 0.9) }                
                &.bx-prev{left:1%;
                    &:after{content:'\f053'; text-indent:-2px; padding: 7px 0;}  
                }            
                &.bx-next{right: 1%;
                    &:after{content:'\f054'; text-indent:4px;padding: 7px 0;}  
                }
            }            
        }        
    }
    .carousel-item{ position: relative; overflow: hidden; 
        .slideOverlay{background:rgba(0, 0, 0, 0.1); width: 100%; height: 100%; position: absolute; z-index: 9;}
        .sliderCaption{position: relative;top:-100%;width: 60%;height: 100%;text-align: center;margin:auto;display: flex;justify-content: center;flex-direction: column;padding:0; z-index: 10;
            @media(max-width:1440px){width: 75%;}
            @media(max-width:1250px){width: 80%;}
            @media(max-width:480px){width: 75%;}
            &.active{top: 0;}
            .leftBorder{ height: 100%; position: relative;
                &::after{right:0;position:absolute; content:'';border-left: 1px solid #fff; border-bottom: 1px solid #fff; border-top: 1px solid #fff; width: 50%; height: 210px;
                    @media(max-width:480px){height: 150px; width: 10px;}
                    @media(max-width:395px){display: none;}
                }
            }
            .rightBorder{height: 100%;position: relative;
                &::after{left:0;position:absolute; content:'';border-right: 1px solid #fff; border-bottom: 1px solid #fff; border-top: 1px solid #fff; width: 50%; height: 210px;
                    @media(max-width:480px){height: 150px; width: 10px;}
                    @media(max-width:395px){display: none;}
                }
            }
            h1{color:#fbfbfb;font-size:50px;
                @media(max-width:1024px){font-size:30px;}
                @media(max-width:480px){font-size:20px; line-height: 30px;}
                @media(max-width:414px){font-size:16px;}
                strong{border-bottom: 5px solid $neyasisRed; font-family: 'Roboto Bold';
                    @media(max-width:480px){border-bottom: 2px solid $neyasisRed;}                    
                }
               
            }
            p{color:#fbfbfb;font-size:18px;font-weight: 400; padding: 20px 0;
                @media(max-width:1024px){font-size:16px;}
                @media(max-width:480px){font-size:12px; line-height: 14px; padding: 0;}    
            }
        }
        &.sliderItem1{
            .slideOverlay{background:rgba(0, 0, 0, 0.6);}
        }
        &.sliderItem2{
            .slideOverlay{background:rgba(0, 0, 0, 0.6);}
        }
        &.sliderItem3{
            .slideOverlay{background:rgba(0, 0, 0, 0.6);}
        }
    
    }
}
#our-references_section{position: relative;
    .bx-wrapper{
        .bx-controls-direction{            
            a{position: absolute;top:0%;height: 100%;background: none;display: flex;justify-content: center;flex-direction: column;text-indent: unset;text-decoration: none;font: normal normal normal 26px/1 FontAwesome;
                &:after{color:$white;text-shadow: 0px 5px 8px #000;display: block;}                
                &.bx-prev{left:5%;
                    &:after{content:'\f053';}  
                }            
                &.bx-next{right: 5%;
                    &:after{content:'\f054';}  
                }
            }
            
        }
    }
    #our-references-carousel{
        .our-references-slide{
            .our-references-item{
                & > a{
                    text-decoration: none;
                    
                    & > img{
                        border:1px solid #f1f1f1;
                        width: 100%;
                        margin:15px auto;
                        display: block;             
                        @media(max-width:992px){margin: 5px auto;}   
                    }

                    &:hover{
                        & > img{
                            /*transform: scale(1.1);
                            box-shadow: 0px 0px 13px -5px #000;*/
                        }
                    }
                }                
               
            }
            
            
        }
        
    }
    .bx-wrapper{
        border:none;
        box-shadow: none;
        padding:0px;
        margin:0px;
        background: none;
        .bx-viewport{
            padding:5px;
        }

        .bx-controls{
            padding-top:35px;
            .bx-pager{
                .bx-pager-item{
                    .bx-pager-link{
                        background: none;
                        text-indent: unset;
                        opacity: 1;
                        color:#b6b6b6;
                        border-radius: 7px;
                        font-size:12px;
                        display: block;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        font-weight: normal;
                        text-decoration: none;
                        &.active{
                            background: $neyasisRed;
                            color:$white;
                        }
                    }
                }
            }
        }
    }

}

a[class*="carousel-"]{position: absolute;top:0;height: 100%;display: flex; justify-content: center;flex-direction: column;text-decoration: none;
    @media(max-width:1024px){height: 50%;}
    @media(max-width:768px){height: 245px;}
    &:after{
        color:#fff;
        font-family: FontAwesome;
        background: rgba(0,0,0,0.5);
        width:35px;
        height:35px;
        border-radius:35px;
        text-align: center;
        padding: 7px;
        font-size:16px;
        transition: all 0.2s;
        
    }
    &:hover::after{ background: rgba(0,0,0,0.9);}
    
    &.carousel-prev-btn{
        left:5%;
        &:after{
            content:'\f053';
            text-indent: -3px;
        }  
    }

    &.carousel-next-btn{
        right: 5%;
        &:after{
            content:'\f054';
            text-indent: 3px;
        }  
    }
}

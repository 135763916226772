// Vendor
@import 'vendors/bootstrap/_index';
@import 'vendors/font-awesome/_index';
@import 'vendors/datatable/_datatable';
@import 'vendors/bxSlider/jquery.bxslider';
// UI
@import 'ui/_variables';
@import 'ui/_typography';
@import 'ui/_alignment';
@import 'ui/_helper';
@import 'ui/_sprite';
@import 'ui/_icons';
@import 'ui/_grid';
@import 'ui/_forms';
@import 'ui/_input-groups';
@import 'ui/_buttons';
@import 'ui/_navbar';
@import 'ui/_navs';
@import 'ui/_tables';
@import 'ui/_const';
@import 'ui/_modal';
@import 'ui/_list';
// Components
@import 'components/_header';
@import 'components/_footer';
@import 'components/_section';
@import 'components/_carousel';
@import 'components/_our-services';
@import 'components/_sidebar';
@import 'components/_sub-page';
@import 'components/_career-page';

.sub-page{
    margin-bottom:30px;
    
    .container{
        @media(max-width:$screen-desktop){
            padding-left:5px;
            padding-right: 5px;
        }
    }

    .page-cover{
        display: flex;
        align-content: center;
        max-height: 335px;
        min-height: 335px;
        position: relative;
        overflow: hidden;
        margin-bottom:35px;
        & > img{
            width: 100%;
            display: block;
            margin:0px auto;
            height: 100%;
            position: absolute;
            top:0;
            left: 0;
            z-index: -1;
        }


        @media(max-width:767px){
            min-height: auto;
            height: 100px!important;
        }

        @media(min-width:$screen-xs) and (max-width:$screen-desktop){
            min-height: auto;
            height: 200px;
        }


        h1{
            position: absolute;
            bottom:50px;
            font-size:60pt;
            color:$white;
            font-weight: bold;
            text-shadow: 0px 5px 3px #848484;
            display: inline-block;
       

            

            @media (min-width:$screen-xs) and (max-width:$screen-desktop) {
                width: 100%;
                font-size:30pt;
                
            }

            @media(max-width:767px){
                bottom:10%;
                font-size:20pt;
                left:10%;
                
            }
        }
    }

    #subpage-content{
        h1{
            font-weight: bold;
            color:$subpageTitleColor;
            font-size:$subpageTitleSize;
        }
    
        p{
            color:$subpageColor;
        }
    
        img{
            width: 100%;
            &.center{
                margin:20px auto;
                display: block;
            }
        }
    
    }

}

header.header{
    .top-mini-menu{ background: rgba(237,31,36,0.7); padding: 0 ;
        @media (max-width:768px) { background: transparent;}
        .container{ padding: 2px 0 !important;
            ul{ float: right; padding: 0; margin: 0 -25px 0 0;
                li{ display: inline-block; padding: 0 5px;
                    a{color: #fff; text-decoration: none; font-size: 12px;}
                }
            }
        }
    }    
    .navbar{border-radius: 0px;background: rgba(70,70,70,0.4);min-height: $headerHeight;margin-bottom: 0px;transition: all .5s ease-in-out;border-bottom:0px;border:none;font-family: 'Roboto Regular';  
        @media (max-width:768px) { position: fixed; width: 100%; z-index:99999999999999999999; padding: 0 2%;}
        .navbar-toggle {border-color:$white;            
            .icon-bar{background-color:$white;}
            &:hover, &:focus{background-color: $white;border-color:$white;
                .icon-bar{background-color: $neyasisRed;}
            }            
        }     
        .container { padding: 10px 0 0 0;
            @media (max-width:767px) { padding: 0;}
        }   
        &.scrollDown{background: $neyasisRed;
            .top-mini-menu{background: rgb(57, 56, 56);
                @media (max-width:768px) { background: transparent;}
                a{color: #fff;}
            }

            .navbar-collapse{                
                .nav{ 
                    & > li{
                        &.active, &:hover{
                            & > a{color:$neyasisRed !important;background: $white !important;}
                        }
                        &:hover a{color:$neyasisRed !important;background: $white !important;}
                    }
                }
            }
        }        
        .navbar-header{          
            @media(max-width:767px){padding-top:15px;}
            .navbar-brand{padding:0px;display: block;
                width: 230px !important;background-size: 100% !important;background-repeat: no-repeat !important; background-position: center !important;
                @include retina-sprite($logo-group);
                @media (max-width:1200px){margin-top:5px;margin-left: 10px;}
                @media (min-width:$screen-xs) and (max-width:$screen-desktop) {float:left;margin:-10px 0 0 15px;padding:0px;background-position:  15px 0px; background-repeat: no-repeat;}
                @media (max-width:480px){margin-top:-15px; margin-left: 15px; }
                @media(max-width:320px){width: 180px; background-repeat: no-repeat; background-size: 100%; background-position: 0px 5px}
            }
        }        
        .navbar-collapse{position: unset;float: right;border-top:none;box-shadow: none; margin: 3px 0 0 0 ;
            @media (max-width:$screen-xs-max) {position: relative;float: none;max-height: 100%;}
            .nav{             
                & > li{position: unset;float: left;margin-left:20px;
                    @media (max-width:$screen-xs) {float: none;position: relative;margin-left:15px;margin-right: 15px;
                        &:last-child{margin-right:10px!important;
                            & > a{border-bottom:none;}
                        }
                        & > a{border-bottom:1px solid $NavBorderColor;text-align: center;border-radius: 0px!important;}                        
                    }
                    &:last-child{margin-right:0px;}
                    & > a{color:$NavColor;padding:7px 10px;border-radius: 5px;
                        @media (min-width:$screen-xs) and (max-width:$screen-desktop) {padding: 7px 7px;}                        
                    }                    
                    &:hover, &.active{
                        & > a{background: $NavActiveBg;}                        
                    }
                    &.dropdown{
                        @media (min-width:$screen-xs-max) and (max-width:$screen-desktop){position: relative;}
                    }
                    &:hover{                        
                        &.dropdown{
                            & > a{
                                &:after{content:'\f0d8';font: normal normal normal 14px/1 FontAwesome;color:$white;position: absolute;bottom:-30px;left: 0px;width: 100%;text-align: center;font-size:32px;
                                    @media (max-width:$screen-xs-max){display: none;}
                                }
                            }
                        }
                        & > .dropdown-wrapper{display: block;}
                    }                    
                    ul{list-style: none;padding: 0px;margin: 0px;}
                    & > .dropdown-wrapper{position: absolute;margin-top:20px;display: none;border-radius: 5px;width: auto;height: auto;min-height: 240px;background: $white;overflow: hidden;
                        @media (max-width:$screen-xs-max){position: unset;}
                        @media (min-width:$screen-xs-max) and (max-width:$screen-desktop){left:0;}
                        &:before{content:' ';clear: both;height: 0px;display: block;}
                        & > ul{list-style: none;padding: 0px;width: auto;overflow: hidden;height: 100%;float: left;
                            @media (max-width:$screen-sm-max) {width: 100%;float: none;}
                            & > li{position: unset;width: 100%;background: #f3f3f3;border-bottom:1px solid $NavBorderColor;
                                & > a{display:block;min-width: 100px;padding:10px;color:$NavDropdownColor;text-decoration: none;
                                    @media(max-width:$screen-xs-max){text-align: center;}                                    
                                }
                                &:last-child{border-bottom:none;}
                                &.dropdown{                                    
                                    & > a {
                                        &:after{content:'\f0da';font: normal normal normal 14px/1 FontAwesome;display: block;float: right;margin-left:10px;
                                            @media(max-width:$screen-sm-max){display: none;}
                                        }                                        
                                    }
                                }    
                                &:hover, &.active{
                                    & > a{background:$NavActiveBg;color:$white;}
                                }                                
                            }
                        }

                        & > .dropdown-sub-wrap{float: left;border-left:1px solid $NavBorderColor;padding:10px;display: none;
                            @media (max-width:$screen-sm-max){display: none!important;}
                            @media(min-width:$screen-tablet) and (max-width:1370px){padding:0px;padding-top:10px;}
                            & > ul{float: left;
                                & > li{padding: 10px;
                                    @media(min-width:$screen-tablet) and (max-width:1370px){padding:7px;}
                                    & > a{display: block;color:$NavDropdownColor;text-decoration: none;
                                        &:hover, &.active{color:$neyasisRed;}
                                    }
                                }
                            }
                            & > img{float: left;border:1px solid #ececec;border-radius: 5px;padding:5px;max-height: 220px;
                                @media(min-width:$screen-tablet) and (max-width:1370px){margin:3px;max-width: 200px;}
                            }
                        }                        
                    }                   
                    
                }
            }
        }

    }
}
.sidebar{
    list-style: none;
    margin: 0px;
    padding: 0px;
    
    a{
        text-decoration: none;
        border-bottom:1px solid $sidebarItemBorderColor;
    }
    & > li{
        transition: all .3s ease-in-out;
        border-left:6px solid $sidebarItemBorderColor;
        border-right:1px solid $sidebarItemBorderColor;
        &:first-child, &.title{
            border-left:6px solid $sidebarTitleBorderColor;
            border-right:1px solid $sidebarTitleBorderColor;    
            background: $sidebarTitleBg;
            color:$sidebarTitleColor;
            padding:20px 15px;
            font-size:22px;
            font-weight: bold;

            @media (max-width:$screen-tablet){
                cursor: pointer;
                &:after{
                    content:'\f0c9';
                    font:normal normal normal 14px/1 FontAwesome;
                    color:$white;
                    font-size:22px;
                    padding:5px 10px;
                    float: right;
                }

                & ~ li{
                    max-height: 0px;
                    overflow: hidden;
                }
            }
        }

        & > a{
            display: block;
            padding:16px;
            background: $sidebarItemBg;
            color:$sidebarItemColor;
            font-weight:600;
        }

        &:hover, &.active{
            & > a {
                color:$sidebarItemActiveColor;
            }
        }

        &.dropdown{
            & > a{
                &:after{
                    content:'\f054';
                    font: normal normal normal 14px/1 FontAwesome;
                    display: inline-block;
                    float: right;
                    transition: all .3s ease-in-out;
                }
            }

            &.active{
                & > a{
                    &:after{
                        transform: rotate(90deg);
                    }
                }

                & > ul{
                    max-height: 500px;  
                }
            }
        }

        & > ul{
            list-style: none;
            padding: 0px;
            background: $sidebarSubBg;
            max-height: 0px;
            overflow: hidden;
            transition: all .3s ease-in-out;
            & > li{
                & > a{
                    display: block;
                    padding:16px;
                    color:$sidebarSubColor;
                    font-weight: normal;
                }

                &:hover, &.active{
                    & > a{
                        color:$sidebarSubActiveColor;
                    }
                }
            }
        }

    }

    &.open{
        & > li {

            max-height: 100%!important;
        }
    }
}
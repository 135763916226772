footer.footer{
    background: $neyasisRed;
    min-height: 60px;
    display: flex;
    align-items: center;
    @media(max-width:$screen-desktop){
        margin-top:40px;
    }
    
    .container{
        @media (min-width:$screen-xs) and (max-width:$screen-desktop){
            display: block;
        }

        @media(max-width:$screen-desktop){
            padding-left:5px;
            padding-right: 5px;
            
        }


    }
    .logo{
        display: block;
        @include retina-sprite($logo-group);
        float: left;
        width: 230px !important;background-size: 100% !important;background-repeat: no-repeat !important; background-position: center !important;

        @media(max-width:$screen-desktop){
            background-size: 230px 35px;
            width: 230px;
            height: 35px;
        }

        @media(max-width:$screen-tablet){
            background-size: 220px 35px;
            width: 220px;
            height: 35px;
        }
        @media(max-width:320px){width: 160px; background-size: 100%; background-repeat: no-repeat; background-position: 0 5px;}
    }
    
    .social-links{
        list-style: none;
        padding: 0px;
        margin: 0px;
        display: table;
        float: right;
        @media (min-width:$screen-xs) and (max-width:$screen-desktop){
            margin-top:5px;
        }
        li{
            float: left;
            margin-right:14px;
            @media (max-width:$screen-xs-max){
                margin-right: 7px;
            }

            
            a{
                text-decoration: none;
                color:$white;
                display: inline-block;
                i.fa{
                    font-size:30px;
                }

                &:hover{
                    opacity: 0.8;
                }
            }
        
        }
    }
}
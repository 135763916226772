#career{

    .datatable_filter{
        border-bottom:1px solid $dTableBorder;
        margin-bottom:25px;
        &:after{
            content:' ';
            display: block;
            clear: both;
            font-size:0px;
            height: 0px;
        }
    }

    &-datatable{
        border-bottom:none;
        margin-bottom:20px;
        
        thead{
            tr{
                background: $dTableHeadBg;
                
                th{
                    font-weight: bold;
                    border:1px solid $dTableHeadBorder;
                    border-right: none;
                    color:$dTableHeadColor;
                    padding:30px 20px;
                    font-size:18px;
                    background-position-x: 95%;
                    &:first-child{
                        border-top-left-radius: $dTableBorderRadius;
                    }

                    &:last-child{
                        border-top-right-radius: $dTableBorderRadius;
                        border-right:1px solid $dTableHeadBorder;
                    }
                }
            }
        }

        tbody{
            tr{
                cursor: pointer;
                
                td{
                    padding:30px 20px;
                    border:1px solid $dTableBodyBorder;
                    border-right: none;
                    border-bottom-color:$dTableBodyBorder;
                    border-bottom-width: 0px;
                    &:first-child{
                        border-left:1px solid $dTableBodyBorder;
                    }
                    &:last-child{
                        border-right: 1px solid $dTableBodyBorder;
                    }
                }

                &:last-child{
                    td{
                        border-bottom: 1px solid $dTableBorder;                        
                        &:first-child{
                            border-bottom-left-radius: $dTableBorderRadius;
                        }
    
                        &:last-child{
                            border-bottom-right-radius: $dTableBorderRadius;
                        }
                    }
                }

                &:hover, &.active{
                    background: $dTableBodyActiveBg;
                    box-shadow: 0px 5px 8px -1px $dTableBodyActiveShadow;
                    transform:scale(1.02);
                    td{
                        border-color:$dTableBodyActiveBorder;
                        color:$dTableBodyActiveColor;
                        border-bottom-width: 1px;
                    }
                }
            }
        }
    }
}

@media (min-width:$screen-sm-max){
    .table-responsive{
        overflow-x: unset;
    }
}

section{ overflow: hidden;background: $sectionBg; opacity: 0;transition: all 1s ease-in-out;
    a{ color: #464646;}
    @media(max-width:$screen-lg-desktop){opacity: 1;}
    @media(max-width:1024px){height: 100vh;}
    @media(max-width:768px){height: auto; min-height: 100vh; padding: 90px 0; }
    &#home-carousel_section{ opacity: 1;}
    &.fade{ opacity: 1;}
    &:after{content:' ';display: block;clear: both;}    
    &:not(:first-child), &:not(:last-child){border-bottom:1px solid #e6e6e6;}    
    h1.section-title{color:$sectionTitleColor;font-size:$sectionTitleSize;text-align: center;margin-bottom:35px;font-family: 'Roboto Bold';
        @media(max-width:768px){font-size: 30px;}
        @media(max-width:480px){font-size: 20px;}
    }
    .section-text{text-align: center;margin:0px auto;font-size: 18px;line-height: 150%;font-family: 'Roboto Light';
        p{ font-size: 20px;
            strong{font-family: 'Roboto Regular';}
            @media(max-width:1024px){font-size:16px;}
            @media(max-width:480px){font-size:12px;}
        }
    }    
    &.justify-center{display: flex;justify-content: center;flex-direction: column;}
    // Sections
    &.carousel{display: flex;justify-content: center;flex-direction: column;overflow: hidden;}
    &#our-references_section{background: #fbfbfb!important;}
    &#contact_section{
        .contact-detail{padding-left:18px;margin-bottom: 25px;
            h3{color:$neyasisRed;margin-left: 10px;}
            .maps-link{color:$textColor;text-decoration: none;}            
            .contact-items{list-style: none;margin: 0px;padding: 0px;
                li{font-weight: 200;margin-bottom: 5px;
                    .fa{margin-right: 5px;}
                }
            }
        }
        .gMaps{
            iframe{width:100%;min-height: 440px;}
        }
    }
    &#home-carousel_section{
        @media(max-width:768px){padding: 0;}
    }
    &#about_section{background-image: url(/assets/images/aboutBg.png); background-repeat: no-repeat; background-position: center center; background-size: 100%;
        @media(max-width:1024px){background-size: cover;}
        @media(max-height:1024px){background-size: cover;}
    } 
    &#our-services_section{
    }
    &#our-references_section{
        @media(max-height:767px){min-height: auto;}
    }
    &#contact_section{
    }
}

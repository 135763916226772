.bx-wrapper {
    position: relative;
    margin-bottom: 60px;
    padding: 0;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -moz-box-shadow: 0 0 5px #ccc;
    -webkit-box-shadow: 0 0 5px #ccc;
    box-shadow: 0 0 5px #ccc;
    border: 5px solid #fff;
    background: #fff;
    img {
      max-width: 100%;
      display: block;
    }
  }
  
  .bxslider {
    margin: 0;
    padding: 0;
  }
  
  ul.bxslider {
    list-style: none;
  }
  
  .bx-viewport {
    -webkit-transform: translateZ(0);
  }
  
  .bx-wrapper {
    .bx-controls-auto, .bx-pager {
      position: absolute;
      bottom: -30px;
      width: 100%;
    }
    .bx-loading {
      min-height: 50px;
      background: url(../images/bxSlider/bx_loader.gif) center center no-repeat #fff;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2000;
    }
    .bx-pager {
      text-align: center;
      font-size: .85em;
      font-family: Arial;
      font-weight: 700;
      color: #666;
      padding-top: 20px;
      &.bx-default-pager a {
        background: #666;
        text-indent: -9999px;
        display: block;
        width: 10px;
        height: 10px;
        margin: 0 5px;
        outline: 0;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        border-radius: 5px;
        &.active, &:focus, &:hover {
          background: #000;
        }
      }
    }
    .bx-controls-auto .bx-controls-auto-item {
      display: inline-block;
      vertical-align: bottom;
    }
    .bx-pager-item {
      display: inline-block;
      vertical-align: bottom;
      font-size: 0;
      line-height: 0;
    }
    .bx-prev {
      left: 10px;
      background: url(../images/bxSlider/controls.png) 0 -32px no-repeat;
      &:focus, &:hover {
        background-position: 0 0;
      }
    }
    .bx-next {
      right: 10px;
      background: url(../images/bxSlider/controls.png) -43px -32px no-repeat;
      &:focus, &:hover {
        background-position: -43px 0;
      }
    }
    .bx-controls-direction a {
      position: absolute;
      top: 50%;
      margin-top: -16px;
      outline: 0;
      width: 32px;
      height: 32px;
      text-indent: -9999px;
      z-index: 9999;
      &.disabled {
        display: none;
      }
    }
    .bx-controls-auto {
      text-align: center;
      .bx-start {
        display: block;
        text-indent: -9999px;
        width: 10px;
        height: 11px;
        outline: 0;
        background: url(../images/bxSlider/controls.png) -86px -11px no-repeat;
        margin: 0 3px;
        &.active, &:focus, &:hover {
          background-position: -86px 0;
        }
      }
      .bx-stop {
        display: block;
        text-indent: -9999px;
        width: 9px;
        height: 11px;
        outline: 0;
        background: url(../images/bxSlider/controls.png) -86px -44px no-repeat;
        margin: 0 3px;
        &.active, &:focus, &:hover {
          background-position: -86px -33px;
        }
      }
    }
    .bx-controls.bx-has-controls-auto.bx-has-pager {
      .bx-pager {
        text-align: left;
        width: 80%;
      }
      .bx-controls-auto {
        right: 0;
        width: 35px;
      }
    }
    .bx-caption {
      position: absolute;
      bottom: 0;
      left: 0;
      background: #666;
      background: rgba(80, 80, 80, 0.75);
      width: 100%;
      span {
        color: #fff;
        font-family: Arial;
        display: block;
        font-size: .85em;
        padding: 10px;
      }
    }
  }
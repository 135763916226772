textarea.form-control{ resize: none;}
.form-group{position: relative;display: flex;align-items: center;
    .form-control{
        &:focus{box-shadow:0px 0px 5px $dTableBodyActiveBg ;border-color:$dTableBodyActiveBg!important;}
    }
    &.floating{
        input.form-control{
            &::placeholder{color:$white;}    
            &:focus, &:not(:placeholder-shown){
                & + label{margin-top:-10px;left:10px;line-height: unset;background: $white;height: auto;display: inline-block;padding:3px;}
            }    
            & + label{
                transition: all .3s ease-in-out;
                position: absolute;
                padding:3px;
                left:15px;
                top:0px;
                margin-bottom:0px;
                color:$formPlaceholderColor;
                display: inline-block;
                font-weight: normal;
                line-height: 40px;
            }
        }
    }
    
    input.form-control{
        height: $formHeight;
        transition: all .3s ease-in-out;

        // Global Settings
        
        &.error{
            border-color:$neyasisRed;
        }

        &.valid{
            border-color:green;
        }        
    }


    &.custom-file-input{
        position: relative;
        height: $formHeight;
        overflow: hidden;
        border:1px solid #ccc;
        border-radius: 4px;
        input[type="file"]{
            display: none;
        }

        label{
            top:0px;
            color:$formPlaceholderColor;
            font-weight: normal;
            position: absolute;
            left:15px;
            line-height: 42px;
            margin:0px;
            height: 100%;
        }

        .btn{
            position: absolute;
            right:0px;
            top:0px;
            padding-top:0px;
            padding-bottom: 0px;
            line-height: 40px;
            background: #d2d5dc;
            color:#fff;
            border-radius: 0px;
            height: 100%;
            border:none;
            color:#424242;
        }
    }
    
}
$sizes :(("n10",-10px),(0,0px),(2,2px),(3,3px),(4,4px),(5,5px),(6,6px),(7,7px),(8,8px),(9,9px),(10,10px),(12,12px),(15,15px),(20,20px),(25,25px),(30,30px),(31,31px),(32,32px),(40,40px),(45,45px),(50,50px),(55,55px),(60,60px));
    @each $n, $size in $sizes {
    .m-t-#{$n} {
        margin-top:$size!important;
    }

    .m-r-#{$n} {
        margin-right:$size!important;
    }
    
    .m-b-#{$n} {
        margin-bottom:$size!important;
    }

    .m-l-#{$n} {
        margin-left:$size!important;
    }

    .p-t-#{$n} {
        padding-top:$size!important;
    }

    .p-r-#{$n} {
        padding-right:$size!important;
    }
    
    .p-b-#{$n} {
        padding-bottom:$size!important;
    }

    .p-l-#{$n} {
        padding-left:$size!important;
    }

    .p-#{$n} {
        padding:$size!important;
    }

    .p--#{$n} {
        padding:$size!important;
    }
    }
    .p-t-0{
    padding-top: 0 !important;
    }

    .p-l-0{
    padding-left: 0 !important;
    }

    .p-r-0{
    padding-right: 0 !important;
    }

    .p-b-0{
    padding-bottom:0 !important;
    }

    .p-v-0{
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    
    .m-t-0{
    margin-top: 0 !important;
    }

    .m-b-0{
    margin-bottom: 0 !important;

    }

    .m-v-0{
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    
    .m-h-0{
    margin-left: 0 !important;
    margin-right: 0 !important;
    }

    
.m-auto{
    margin-left: auto;
    margin-right: auto;
}

.t-center{
    text-align: center;
}

.t-upper{
    text-transform: uppercase;
}

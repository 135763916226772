// Variables
$white:#fff;
$black:#000;
$neyasisRed:#ed1f24;
$neyasisDarkRed:#b70409;

$textColor:#464646;

// Header
$headerNavBg:$neyasisRed;

$headerHeight:83px;

$NavColor:$white;
$NavActiveBg:$neyasisRed;
$NavActiveColor:$white;


$NavBorderColor:#cdcdcd;

$NavDropdownColor:#474747;
$NavDropdownActiveBg:$neyasisRed;

$NavBorderColor : #fbfbfb;



$sectionBg:$white;
$sectionTitleColor:#464646;
$sectionTitleSize:22pt;

// Sidebar

$sidebarTitleBg:$neyasisRed;
$sidebarTitleColor:$white;
$sidebarTitleBorderColor:#b4181c;

$sidebarItemBg:#eaeaea;
$sidebarItemColor:#474747;
$sidebarItemBorderColor:#d5d5d5;
$sidebarItemActiveColor:$neyasisRed;

$sidebarSubBg:#fcfcfc;
$sidebarSubColor:#888888;
$sidebarSubActiveColor:$sidebarItemActiveColor;


// Our Service Section

$our-service-color:#464646;

$software: #02a5f3;
$outSource: #25cd75;
$careSupport: #e43513;
$educationalServices: #fa8023;
$uxDesign: #71a5b0;
$dataManagement: #7249c9;

// Subpage

$subpageTitleColor:#464646;
$subpageTitleSize:18px;
$subpageColor:$subpageTitleColor;


// Datatable
$dTableBorder:#eeeeee;
$dTableBorderRadius:5px;

$dTableHeadBg:$neyasisRed;
$dTableHeadColor:$white;
$dTableHeadBorder:$neyasisDarkRed;

$dTableBodyBg:$white;
$dTableBodyBorder:#eeeeee;
$dTableBodyColor:#464646;

$dTableBodyActiveBg:#05a6f0;
$dTableBodyActiveBorder:#0681ba;
$dTableBodyActiveColor:$white;

$dTableBodyActiveShadow:#05a6f0;

// Form

$formHeight:45px;
$formBorderRadius:5px;

$formPlaceholderColor:#b3b2b2;


// Appeal Modal

$appealModalBorder:#d2d5dc;

#our-services_section{
    .our-services{margin-top:35px;
        &-item{ min-height:360px; max-height:360px;padding: 0px;padding-left: 2px;padding-right: 2px;cursor:pointer; border-bottom: 1px solid #e8e8e8; border-right:1px solid #e8e8e8;
            @media(max-width:$screen-desktop){margin-bottom:10px;}
            @media(max-width:767px){min-height:300px; max-height:300px;}
            & > a{text-decoration: none;color:$our-service-color; cursor: auto;}
            /*&:hover{
                .our-services-item-text{color:$white;}
                .our-services-item-image-hover{display: flex;}
                .our-services-item-image{display: none;}
            }*/
            &-image{overflow: hidden;display: flex;justify-content: center; padding: 70px 0 130px 0; 
                @media(max-width:767px){min-height: 230px; max-height: 230px; padding: 70px 0 100px 0;}  
                img{
                    @media(max-width:767px){width:50px; height: 50px;} 
                }           
                &-hover{overflow: hidden;display: none;justify-content: center; padding: 70px 0 130px 0;
                    @media(max-width:767px){min-height: 230px; max-height: 230px; padding: 70px 0 100px 0;} 
                    img{
                        @media(max-width:767px){width:50px; height: 50px;} 
                    }
                }     
            }
            &::after{width: 100%; height: 5px; position: absolute; left: 0; top: 0; content: '';}  
            &-text{transition: all 0.05s ease-in-out; font-size:17px; margin: -60px 0 0 0; padding: 0 0 50px 0;text-align: center;min-height: 135px;display: flex;align-items: center; width: 100%;
                @media(max-width:480px){font-size: 12px;}
                p{transition: all 0.05s ease-in-out;text-align: center; width: 100%; font-family: 'Roboto Regular'; line-height: 22px;}
                strong{transition: all 0.05s ease-in-out; padding: 0; margin: 0; font-family: 'Roboto Bold';}
            }
            &#software{border-left: 1px solid #e8e8e8;border-top: 1px solid #e8e8e8;
                //&:before{ background: $software;}
                //&::after{ background: $software;}
                &.our-services-item-text{ color: #fff;}
            }
            &#out-source{border-top: 1px solid #e8e8e8;
               // &:before{background: $outSource;}
                //&::after{ background: $outSource;}
            }
            &#care-support{border-top: 1px solid #e8e8e8;
                //&:before{background: $careSupport;}
                //&::after{ background: $careSupport;}
            }
            &#educational-services{border-top: 1px solid #e8e8e8;
                //&:before{background: $educationalServices;}
                //&::after{ background: $educationalServices;}
            }
            &#ux-design{border-top: 1px solid #e8e8e8;
                //&:before{background: $uxDesign;}
                //&::after{ background: $uxDesign;}
            }
            &#data-management{border-top: 1px solid #e8e8e8;
                //&:before{background: $dataManagement;}
                //&::after{ background: $dataManagement;}
            }
        }
    }
}
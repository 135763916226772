.visuallyhidden{
  border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px;
  overflow: hidden; padding: 0; position: absolute; width: 1px;
}


.overflow-x-hidden{
  overflow-x: hidden !important;

}


.relative{
  position:relative;
}

.t-100{
  display:table;
  width:100%;

}

.d-block{
  display: block;
}

.t{
  display:table!important;
}

.t-cell{
  display:table-cell;
  vertical-align: middle;
}



.img-responsive{
  width: 100%;
}


*{
  transition: all .3s ease-in-out;
}
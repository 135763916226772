.btn{
    &-nred{
        background: $neyasisRed;
        border:none;
        color:$white;
        padding:19px;
        font-weight: 600;
        

        &:hover, &:focus, &.active{
            box-shadow: 2px 3px 5px $neyasisRed;
            color:$white;

        }
    }

    & > i.fa{
        margin-left:60px;
    }
}
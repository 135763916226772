@font-face {
    font-family: 'Roboto Light';
	src: url('../fonts/Roboto/Roboto-Light.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}


@font-face {
    font-family: 'Roboto Regular';
	src: url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}


@font-face {
    font-family: 'Roboto Semi-Bold';
	src: url('../fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Bold';
	src: url('../fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

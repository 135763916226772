table.dataTable {
    width: 100%;
    margin: 0 auto;
    clear: both;
    border-collapse: separate;
    border-spacing: 0;
    thead th, tfoot th {
      font-weight: bold;
    }
    thead {
      th, td {
        padding: 10px 18px;
        border-bottom: 1px solid #111;
      }
      th:active, td:active {
        outline: none;
      }
    }
    tfoot {
      th, td {
        padding: 10px 18px 6px 18px;
        border-top: 1px solid #111;
      }
    }
    thead {
      .sorting, .sorting_asc, .sorting_desc, .sorting_asc_disabled, .sorting_desc_disabled {
        cursor: pointer;
        *cursor: hand;
        background-repeat: no-repeat;
        background-position: center right;
      }
      .sorting {
        background-image: url("../images/sort_both.png");
      }
      .sorting_asc {
        background-image: url("../images/sort_asc.png");
      }
      .sorting_desc {
        background-image: url("../images/sort_desc.png");
      }
      .sorting_asc_disabled {
        background-image: url("../images/sort_asc_disabled.png");
      }
      .sorting_desc_disabled {
        background-image: url("../images/sort_desc_disabled.png");
      }
    }
    tbody {
      tr {
        background-color: #ffffff;
        &.selected {
          background-color: #B0BED9;
        }
      }
      th, td {
        padding: 8px 10px;
      }
    }
    &.row-border tbody {
      th, td {
        border-top: 1px solid #ddd;
      }
    }
    &.display tbody {
      th, td {
        border-top: 1px solid #ddd;
      }
    }
    &.row-border tbody tr:first-child {
      th, td {
        border-top: none;
      }
    }
    &.display tbody tr:first-child {
      th, td {
        border-top: none;
      }
    }
    &.cell-border tbody {
      th, td {
        border-top: 1px solid #ddd;
        border-right: 1px solid #ddd;
      }
      tr {
        th:first-child, td:first-child {
          border-left: 1px solid #ddd;
        }
        &:first-child {
          th, td {
            border-top: none;
          }
        }
      }
    }
    &.stripe tbody tr.odd, &.display tbody tr.odd {
      background-color: #f9f9f9;
    }
    &.stripe tbody tr.odd.selected, &.display tbody tr.odd.selected {
      background-color: #acbad4;
    }
    &.hover tbody tr:hover, &.display tbody tr:hover {
      background-color: #f6f6f6;
    }
    &.hover tbody tr:hover.selected, &.display tbody tr:hover.selected {
      background-color: #aab7d1;
    }
    &.order-column tbody tr > {
      .sorting_1, .sorting_2, .sorting_3 {
        background-color: #fafafa;
      }
    }
    &.display tbody tr > {
      .sorting_1, .sorting_2, .sorting_3 {
        background-color: #fafafa;
      }
    }
    &.order-column tbody tr.selected > {
      .sorting_1, .sorting_2, .sorting_3 {
        background-color: #acbad5;
      }
    }
    &.display tbody tr {
      &.selected > {
        .sorting_1, .sorting_2, .sorting_3 {
          background-color: #acbad5;
        }
      }
      &.odd > .sorting_1 {
        background-color: #f1f1f1;
      }
    }
    &.order-column.stripe tbody tr.odd > .sorting_1 {
      background-color: #f1f1f1;
    }
    &.display tbody tr.odd > .sorting_2, &.order-column.stripe tbody tr.odd > .sorting_2 {
      background-color: #f3f3f3;
    }
    &.display tbody tr.odd > .sorting_3, &.order-column.stripe tbody tr.odd > .sorting_3 {
      background-color: whitesmoke;
    }
    &.display tbody tr.odd.selected > .sorting_1, &.order-column.stripe tbody tr.odd.selected > .sorting_1 {
      background-color: #a6b4cd;
    }
    &.display tbody tr.odd.selected > .sorting_2, &.order-column.stripe tbody tr.odd.selected > .sorting_2 {
      background-color: #a8b5cf;
    }
    &.display tbody tr.odd.selected > .sorting_3, &.order-column.stripe tbody tr.odd.selected > .sorting_3 {
      background-color: #a9b7d1;
    }
    &.display tbody tr.even > .sorting_1, &.order-column.stripe tbody tr.even > .sorting_1 {
      background-color: #fafafa;
    }
    &.display tbody tr.even > .sorting_2, &.order-column.stripe tbody tr.even > .sorting_2 {
      background-color: #fcfcfc;
    }
    &.display tbody tr.even > .sorting_3, &.order-column.stripe tbody tr.even > .sorting_3 {
      background-color: #fefefe;
    }
    &.display tbody tr.even.selected > .sorting_1, &.order-column.stripe tbody tr.even.selected > .sorting_1 {
      background-color: #acbad5;
    }
    &.display tbody tr.even.selected > .sorting_2, &.order-column.stripe tbody tr.even.selected > .sorting_2 {
      background-color: #aebcd6;
    }
    &.display tbody tr.even.selected > .sorting_3, &.order-column.stripe tbody tr.even.selected > .sorting_3 {
      background-color: #afbdd8;
    }
    &.display tbody tr:hover > .sorting_1, &.order-column.hover tbody tr:hover > .sorting_1 {
      background-color: #eaeaea;
    }
    &.display tbody tr:hover > .sorting_2, &.order-column.hover tbody tr:hover > .sorting_2 {
      background-color: #ececec;
    }
    &.display tbody tr:hover > .sorting_3, &.order-column.hover tbody tr:hover > .sorting_3 {
      background-color: #efefef;
    }
    &.display tbody tr:hover.selected > .sorting_1, &.order-column.hover tbody tr:hover.selected > .sorting_1 {
      background-color: #a2aec7;
    }
    &.display tbody tr:hover.selected > .sorting_2, &.order-column.hover tbody tr:hover.selected > .sorting_2 {
      background-color: #a3b0c9;
    }
    &.display tbody tr:hover.selected > .sorting_3, &.order-column.hover tbody tr:hover.selected > .sorting_3 {
      background-color: #a5b2cb;
    }
    &.no-footer {
      border-bottom: 1px solid #111;
    }
    &.nowrap {
      th, td {
        white-space: nowrap;
      }
    }
    &.compact {
      thead {
        th, td {
          padding: 4px 17px 4px 4px;
        }
      }
      tfoot {
        th, td {
          padding: 4px;
        }
      }
      tbody {
        th, td {
          padding: 4px;
        }
      }
    }
    th.dt-left, td.dt-left {
      text-align: left;
    }
    th.dt-center {
      text-align: center;
    }
    td {
      &.dt-center, &.dataTables_empty {
        text-align: center;
      }
    }
    th.dt-right, td.dt-right {
      text-align: right;
    }
    th.dt-justify, td.dt-justify {
      text-align: justify;
    }
    th.dt-nowrap, td.dt-nowrap {
      white-space: nowrap;
    }
    thead {
      th.dt-head-left, td.dt-head-left {
        text-align: left;
      }
    }
    tfoot {
      th.dt-head-left, td.dt-head-left {
        text-align: left;
      }
    }
    thead {
      th.dt-head-center, td.dt-head-center {
        text-align: center;
      }
    }
    tfoot {
      th.dt-head-center, td.dt-head-center {
        text-align: center;
      }
    }
    thead {
      th.dt-head-right, td.dt-head-right {
        text-align: right;
      }
    }
    tfoot {
      th.dt-head-right, td.dt-head-right {
        text-align: right;
      }
    }
    thead {
      th.dt-head-justify, td.dt-head-justify {
        text-align: justify;
      }
    }
    tfoot {
      th.dt-head-justify, td.dt-head-justify {
        text-align: justify;
      }
    }
    thead {
      th.dt-head-nowrap, td.dt-head-nowrap {
        white-space: nowrap;
      }
    }
    tfoot {
      th.dt-head-nowrap, td.dt-head-nowrap {
        white-space: nowrap;
      }
    }
    tbody {
      th.dt-body-left, td.dt-body-left {
        text-align: left;
      }
      th.dt-body-center, td.dt-body-center {
        text-align: center;
      }
      th.dt-body-right, td.dt-body-right {
        text-align: right;
      }
      th.dt-body-justify, td.dt-body-justify {
        text-align: justify;
      }
      th.dt-body-nowrap, td.dt-body-nowrap {
        white-space: nowrap;
      }
    }
    box-sizing: content-box;
    th, td {
      box-sizing: content-box;
    }
  }
  
  .dataTables_wrapper {
    position: relative;
    clear: both;
    *zoom: 1;
    zoom: 1;
    .dataTables_length {
      float: left;
    }
    .dataTables_filter {
      float: right;
      text-align: right;
      input {
        margin-left: 0.5em;
      }
    }
    .dataTables_info {
      clear: both;
      float: left;
      padding-top: 0.755em;
    }
    .dataTables_paginate {
      float: right;
      text-align: right;
      padding-top: 0.25em;
      .paginate_button {
        box-sizing: border-box;
        display: inline-block;
        min-width: 1.5em;
        padding: 0.5em 1em;
        margin-left: 2px;
        text-align: center;
        text-decoration: none !important;
        cursor: pointer;
        *cursor: hand;
        color: #333 !important;
        border: 1px solid transparent;
        border-radius: 2px;
        &.current {
          color: #333 !important;
          border: 1px solid #979797;
          background-color: white;
          background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, #dcdcdc));
          background: -webkit-linear-gradient(top, #fff 0%, #dcdcdc 100%);
          background: -moz-linear-gradient(top, #fff 0%, #dcdcdc 100%);
          background: -ms-linear-gradient(top, #fff 0%, #dcdcdc 100%);
          background: -o-linear-gradient(top, #fff 0%, #dcdcdc 100%);
          background: linear-gradient(to bottom, #fff 0%, #dcdcdc 100%);
          &:hover {
            color: #333 !important;
            border: 1px solid #979797;
            background-color: white;
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, #dcdcdc));
            background: -webkit-linear-gradient(top, #fff 0%, #dcdcdc 100%);
            background: -moz-linear-gradient(top, #fff 0%, #dcdcdc 100%);
            background: -ms-linear-gradient(top, #fff 0%, #dcdcdc 100%);
            background: -o-linear-gradient(top, #fff 0%, #dcdcdc 100%);
            background: linear-gradient(to bottom, #fff 0%, #dcdcdc 100%);
          }
        }
        &.disabled {
          cursor: default;
          color: #666 !important;
          border: 1px solid transparent;
          background: transparent;
          box-shadow: none;
          &:hover, &:active {
            cursor: default;
            color: #666 !important;
            border: 1px solid transparent;
            background: transparent;
            box-shadow: none;
          }
        }
        &:hover {
          color: white !important;
          border: 1px solid #111;
          background-color: #585858;
          background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111));
          background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
          background: -moz-linear-gradient(top, #585858 0%, #111 100%);
          background: -ms-linear-gradient(top, #585858 0%, #111 100%);
          background: -o-linear-gradient(top, #585858 0%, #111 100%);
          background: linear-gradient(to bottom, #585858 0%, #111 100%);
        }
        &:active {
          outline: none;
          background-color: #2b2b2b;
          background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2b2b2b), color-stop(100%, #0c0c0c));
          background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
          background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
          background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
          background: -o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
          background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
          box-shadow: inset 0 0 3px #111;
        }
      }
      .ellipsis {
        padding: 0 1em;
      }
    }
    .dataTables_processing {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 40px;
      margin-left: -50%;
      margin-top: -25px;
      padding-top: 20px;
      text-align: center;
      font-size: 1.2em;
      background-color: white;
      background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
      background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
      background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
      background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
    }
    .dataTables_length, .dataTables_filter, .dataTables_info, .dataTables_processing, .dataTables_paginate {
      color: #333;
    }
    .dataTables_scroll {
      clear: both;
      div.dataTables_scrollBody {
        *margin-top: -1px;
        -webkit-overflow-scrolling: touch;
        > table > {
          thead > tr > {
            th, td {
              vertical-align: middle;
            }
          }
          tbody > tr > {
            th, td {
              vertical-align: middle;
            }
          }
          thead > tr > {
            th > div.dataTables_sizing, td > div.dataTables_sizing {
              height: 0;
              overflow: hidden;
              margin: 0 !important;
              padding: 0 !important;
            }
          }
          tbody > tr > {
            th > div.dataTables_sizing, td > div.dataTables_sizing {
              height: 0;
              overflow: hidden;
              margin: 0 !important;
              padding: 0 !important;
            }
          }
        }
      }
    }
    &.no-footer {
      .dataTables_scrollBody {
        border-bottom: 1px solid #111;
      }
      div {
        &.dataTables_scrollHead table.dataTable, &.dataTables_scrollBody > table {
          border-bottom: none;
        }
      }
    }
    &:after {
      visibility: hidden;
      display: block;
      content: "";
      clear: both;
      height: 0;
    }
  }
  
  @media screen and (max-width: 767px) {
    .dataTables_wrapper {
      .dataTables_info {
        float: none;
        text-align: center;
      }
      .dataTables_paginate {
        float: none;
        text-align: center;
        margin-top: 0.5em;
      }
    }
  }
  
  @media screen and (max-width: 640px) {
    .dataTables_wrapper {
      .dataTables_length {
        float: none;
        text-align: center;
      }
      .dataTables_filter {
        float: none;
        text-align: center;
        margin-top: 0.5em;
      }
    }
  }
.modal{

    @media(min-width:$screen-xs){
        &.in{
            display: flex!important;
            align-items: center;
            .modal-dialog{
                display: flex;
                align-items: center;
            }
        }
    }

    .modal-header{
        padding:10px;
        border-bottom:none;
        
        button.close{
            background: #333;
            border:none;
            border-radius: 3px;
            padding:0px;
            height: auto;
            float: right;
            &:after{
                padding:5px 7px;
                color:#fff;
                content: '\f00d';
                font:normal normal normal 14px/1 FontAwesome;
            }
            
        }

    }

    .modal-body{
        h1{
            font-weight: bold;
            font-size:18px;
            margin-bottom:24px;
            margin-top:0px;
        }

        

    }
}


#appealModal{
    
    .modal-dialog{
        position: relative;
        perspective: 2280px;
        transform-style: preserve-3d;
        
        &:after{
            content:' ';
            clear: both;
            display: block;

        }

        @media(min-width:$screen-xs){
            width: 700px;
        }

        .modal-content{
            backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
            transform-style: preserve-3d;
            z-index: 0;
            width: 100%;
            transition: all 1s ease-in-out;
            position: absolute;
            border:1px solid $appealModalBorder;
            border-radius: 0px;
            
            
            &:after{
                content:' ';
                display: block;
            }
            
            
            
            .modal-body{
                @media(min-width:$screen-xs) and (max-width: $screen-desktop){
                    min-height: 400px;
                    
                    overflow: scroll;
                    
                }

                
                ul{
                    list-style: none;
                    padding:0px;
                    margin:0px;
            
                    li{
                        padding-left:15px;
                        position: relative;
                        margin-bottom:10px;
                        &:before{
                            content:'\f192';
                            font:normal normal normal 14px/1 FontAwesome;
                            position: absolute;
                            left: 0;
                            top: 2px;
                        }
                    }
                }

                
            }
            
        }

        #appeal-step-one{
            transform: rotateY(0deg);
            background: $white;
        }

        #appeal-step-two{
            transform: rotateY(180deg);
            background: $white;
            @media (max-width:$screen-desktop) {
                visibility: hidden;
                opacity: 0;
                
                
            }
            
        }
    }
    &.flip{
        #appeal-step-one{
            transform: rotateY(-180deg);
            @media (max-width:$screen-desktop) {
                visibility: hidden;
                opacity: 0;

            }
        }

        #appeal-step-two{
            transform: rotateY(0deg);
            @media (max-width:$screen-desktop) {
                visibility: visible;
                opacity: 1;
                .g-recaptcha{
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }


    .modal-footer{
        border-top:none;
        padding-top:0px;
        span.appeal-code{
            float: left;
            display: inline-block;
            padding:19px;
            color:$neyasisRed;
            font-weight: bold;
            font-size: 16pt;
        }

        .recaptcha{
            display: inline-block;
            float: left;
            @media(max-width:$screen-tablet){
                transform: scale(0.70);
                transform-origin: 0 0;
                margin-top:10px;
                
            }
        }

        .btn{
            margin-top:10px;
            float: right;
        }

    }

}

/* Sweep To Bottom */
.hvr-sweep-to-bottom {    display: inline-block;    vertical-align: middle;    -webkit-transform: perspective(1px) translateZ(0);    transform: perspective(1px) translateZ(0);    box-shadow: 0 0 1px transparent;    position: relative;    -webkit-transition-property: color;    transition-property: color;    -webkit-transition-duration: 0.3s;    transition-duration: 0.3s;  }
  .hvr-sweep-to-bottom:before {    content: "";    position: absolute;    z-index: -1;    top: 0;    left: 0;    right: 0;    bottom: 0;    background: #2098D1;    -webkit-transform: scaleY(0);transform: scaleY(0);-webkit-transform-origin: 50% 0; transform-origin: 50% 0; -webkit-transition-property: transform; transition-property: transform; -webkit-transition-duration: 0.5s; transition-duration: 0.5s; -webkit-transition-timing-function: ease-out;transition-timing-function: ease-out;}
  .hvr-sweep-to-bottom:hover, .hvr-sweep-to-bottom:focus, .hvr-sweep-to-bottom:active {    color: white;  }
  .hvr-sweep-to-bottom:hover:before, .hvr-sweep-to-bottom:focus:before, .hvr-sweep-to-bottom:active:before {    -webkit-transform: scaleY(1);    transform: scaleY(1);  }

  /**/
  .saver {
	clear: both !important;
	height: 0px !important;
	line-height: 0px !important;
	float: none !important;
	display: block !important;
}
.hiddenText {
	font-size: 0px !important;
	text-indent: -9999px !important;
}